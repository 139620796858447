import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

import useHover from '@assets/scripts/hooks/useHover';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#FFFE54';
const footerRelatedLinks = [
	// { title: 'Other Projects', url: '/en/projects/' },
	{
		title: 'Les chauffeurs de Rémy',
		url: '/en/projects/les-chauffeurs-de-remy/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/mjf/mjf_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: { eq: "projects/mjf/mjf_hero_mobile.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_1: file(relativePath: { eq: "projects/mjf/mjf_1.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_2: file(relativePath: { eq: "projects/mjf/mjf_2.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1600, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_3: file(relativePath: { eq: "projects/mjf/mjf_3.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_4: file(relativePath: { eq: "projects/mjf/mjf_4.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_5: file(relativePath: { eq: "projects/mjf/mjf_5.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_6: file(relativePath: { eq: "projects/mjf/mjf_6.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_7: file(relativePath: { eq: "projects/mjf/mjf_7.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_8: file(relativePath: { eq: "projects/mjf/mjf_8.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_9: file(relativePath: { eq: "projects/mjf/mjf_9.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_10: file(relativePath: { eq: "projects/mjf/mjf_10.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_11: file(
					relativePath: { eq: "projects/mjf/mjfmedia_1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1400, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_12: file(
					relativePath: { eq: "projects/mjf/mjfmedia_2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1400, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_13: file(relativePath: { eq: "projects/mjf/mjf13.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1400, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{ uri: '/fr/projets/montreux-jazz-festival/', lang: 'fr' },
			]}
			title="Montreux Jazz Festival - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Montreux Jazz Festival"
				image={{
					...data.heroImage.childImageSharp,
					caption:
						'Rag’n’Bone Man performing in Montreux, by Florian Aeby',
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
					caption:
						'Rag’n’Bone Man performing in Montreux, by Florian Aeby',
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Identify new business models and combine them with a
							web solution for one of the world's most emblematic
							festivals.
						</p>

						<div className="list-two-columns">
							<ListText
								title="Our services"
								list={[
									'User research',
									'Branding workshops',
									'Strategy workshops',
									'Digital strategy and design',
									'Web development',
								]}
							/>
						</div>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							The Montreux Jazz needs no introduction. The
							international festival is famous for its legendary
							history and outstanding musical performances. To
							keep making a lasting impression, the festival
							drafted in superhuit to unearth new business models
							and develop a web tool to serve these markets. All
							while enhancing the digital user experience related
							to the festival.
						</p>
						<h3>Our response</h3>
						<p>
							A design strategy based on user research, sector
							analysis, strategic workshops with the different MJF
							teams, and a lot of collaboration and ideation
							sessions to pinpoint new opportunities.
						</p>
						<p>
							We repositioned the Montreux Jazz Festival brand and
							rebuilt its architecture. This resulted in the
							creation of a bespoke web solution, offering both
							the flexibility and the scalability that the
							festival needs. Our solution is set up to allow the
							MJF to digitalize its business models in stages,
							making sure that the visual experience brings the
							brand's DNA to life.
						</p>

						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.montreuxjazzfestival.com/"
								text="Discover the site"
							/>
							{/*
								<ButtonSecondary
								href="/todo"
								text="Case study"
								data-target
							/>
							 */}
						</div>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>DNA/Brand Personality</h3>
							<p>
								Defining a brand personality is central to
								positioning. We carried out workshops and
								identified brand tensions and terminologies that
								today define the specific brand DNA.
							</p>
						</div>
						<Image {...data.mjf_1.childImageSharp} alt="Logo" />
					</div>

					{/* article à ajouter
					}<ItemArticle
						className="element-spacing"
						modifiers="small"
						link={{ title: 'Read article', url: '/todo' }}
						title="Type design and branding: The inevitable alliance"
					/>
					*/}
				</div>

				<Video
					className="col-full"
					src="f789a8b76450eaab08d714c622ecd2a5"
					alt="Page transition"
				/>

				<div className="box text-content" data-animation-page>
					<h3>Vinyl: musical heritage</h3>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								The visual approach revolves around the desire
								to offer an elegant and timeless design, which
								communicates both the musical world of legends
								and the DNA of the Montreux Jazz Festival brand.
								This axis also makes it possible to dress the
								site with the edition's poster.
							</p>
						</div>
						<div className="text-content">
							<p>
								What better than vinyl records to articulate
								their authentic musical heritage? Its grooves
								inspire the simple lines of the site’s main
								visuals.
							</p>
						</div>
					</div>
					<div>
						<Video
							src="a35774b7eb99aa6fd427fe8da3166e05"
							alt="Animation concept"
						/>
					</div>
				</div>

				<Column>
					<div className="text-content column__sticky column__text">
						<h3>
							A brand is also brought to life by its animations
						</h3>
						<p>
							The site relies on subtle animations that simplify
							the user experience by providing information.
						</p>
						<p>
							Animations are also a powerful way to consolidate
							the brand's expression.
						</p>

						{/*
						<ItemArticle
							className="element-spacing"
							modifiers="small"
							link={{ title: 'Read article', url: '/todo' }}
							title="Type design and branding: The inevitable alliance"
						/>
						*/}
					</div>

					<div className="text-content column__media">
						<Video
							src="8b7a7760b11dd2e11dce5305aa6a4bba"
							alt="Scrolling in the MJF website"
						/>
						<Video
							className="col-full"
							src="7404d786f2f12c6a5e22ed8123ca8638"
							alt="Video showing how the menu animates"
						/>
					</div>
				</Column>

				{/***** VALORISATION TYPO *****/}
				<div className="box text-content" data-animation-page>
					<h3>Typographical evolution</h3>
					<div className="list-two-columns align-items-center">
						<Image
							{...data.mjf_3.childImageSharp}
							alt="Typography"
						/>
						<Image
							{...data.mjf_4.childImageSharp}
							alt="Typography"
						/>
					</div>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								The introduction of a new title font - Noe Text,
								designed by independent type foundry{' '}
								<a
									href="https://www.schick-toikka.com/"
									target="_blank"
									rel="noopener noreferrer"
									data-target
									onMouseEnter={() => hoverOn()}
									onMouseLeave={() => hoverOff()}
								>
									Schick Toikka
								</a>{' '}
								- sharpens the brand concept and enhances their
								uniqueness.
							</p>
						</div>
						<div className="text-content">
							<p>
								The new font forms a perfect link between
								heritage and innovation, reinforcing both
								elegance and boldness.
							</p>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="bd01d26296ca47b369bd2340dfe2c854"
					alt="Media page on MJF website"
				/>

				{/***** VALORISATION TYPO *****/}
				<div className="box text-content" data-animation-page>
					<h3>
						Transforming the website into a fully-fledged multimedia
						platform
					</h3>
					<div className="list-two-columns">
						<div className="text-content">
							<p>
								A media platform has been developed to both
								cultivate the heritage of the Montreux Jazz
								Festival and generate a new source of income
								linked to a new mixed music model.
							</p>
						</div>
						<div className="text-content">
							<p>
								It will allow users to delve deeper into the
								diversity of the festival's content. Special
								formats allow them to unearth rising stars,
								discover new musical trends and, of course,
								relive legendary concerts.
							</p>
							<p>
								The media platform is designed to evolve to the
								beat of the future needs of the Montreux Jazz
								Festival.
							</p>
							<p></p>
						</div>
					</div>
				</div>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.mjf_11.childImageSharp}
						alt="Plateforme média version mobile"
					/>
					<Image
						{...data.mjf_12.childImageSharp}
						alt="Plateforme média version mobile"
					/>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle="Customer Testimonial"
					quote="superhuit has been a truly strategic partner in the development of our digital strategy and identifying new business universes."
					more="They offered attractive and engaging workshops, and 1:1 sessions to design and simplify a complex and multi-channel digital portfolio. This resulted in a great progressive web experience that can evolve according to the needs of our media company. Ours is a very committed and passionate partnership based on trust in their expertise, with creativity firmly at the heart of everything we do together."
					cite="Michelle Goff – Chief Digital Officer, Montreux Media Venture"
				/>

				<Video
					className="col-big"
					src="ff9de491119c285837f240ad598d0c4e"
					alt="MJF Programme"
				/>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-top justify-normal">
						<div className="text-content">
							<h3>
								Improved user experience related to program
								discovery
							</h3>
						</div>
						<div className="text-content">
							<p>
								With nearly 400 concerts, paid and free, spread
								across 11 stages over 16 days, the Montreux Jazz
								Festival program has its complexities and
								challenges. The huge task of structuring all the
								information was undertaken to make it easier to
								understand and use.
							</p>
							<p>
								<small>
									(The program shown in the examples is
									fictitious)
								</small>
							</p>
						</div>
					</div>
				</div>

				<Gallery className="col-full" modifiers="gutter" s>
					<Image
						{...data.mjf_8.childImageSharp}
						alt="Mobile Design"
					/>
					<Image
						{...data.mjf_9.childImageSharp}
						alt="Mobile Design"
					/>
				</Gallery>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
